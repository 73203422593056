import Popup from '@/components/Popup';
import SEO from '@/components/SEO';
import GlobalStyle from '@/GlobalStyle';
import React, { FC } from 'react';

const BaseLayout: FC = ({ children }) => (
  <>
    <GlobalStyle />
    <SEO />
    {children}
    <Popup />
  </>
);

export default BaseLayout;
