import Button from './Button';
import PopupModal from './PopupModal';
import { usePopupStore } from '@/stores/PopupStore';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

const Popup: FC = observer(() => {
  const popupStore = usePopupStore();

  return (
    <PopupModal
      isOpen={popupStore.isOpen}
      dismissable
      onRequestClose={() => popupStore.onClose(false)}
      maxWidth="400px"
      message={popupStore.message}
      footer={
        popupStore.footer ? (
          popupStore.footer
        ) : (
          <>
            {popupStore.isCancelShown && (
              <Button
                size="sm"
                outline
                onClick={() => popupStore.onClose(false)}
              >
                취소
              </Button>
            )}
            <Button size="sm" onClick={() => popupStore.onClose(true)}>
              확인
            </Button>
          </>
        )
      }
    />
  );
});

export default Popup;
