import Modal, { ModalProps } from './Modal';
import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const PopupBody = styled.div`
  white-space: pre-wrap;
  text-align: center;
  padding: 32px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
`;

const PopupFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  > * {
    margin: 0 20px;
  }
`;

interface PopupModalProps extends ModalProps {
  message?: ReactNode;
  footer?: ReactElement;
}

const PopupModal: FC<PopupModalProps> = ({ message, footer, ...props }) => (
  <Modal dismissable maxWidth="400px" {...props}>
    <PopupBody>{message}</PopupBody>
    <PopupFooter>{footer}</PopupFooter>
  </Modal>
);

export default PopupModal;
