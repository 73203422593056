import { observable } from 'mobx';
import { ReactNode } from 'react';

// Store 타입 정의
interface PopupStoreProps {
  isOpen: boolean;
  isCancelShown: boolean;
  message: ReactNode | string;
  title: string;
  footer?: ReactNode;
  onConfirm?: (result: boolean) => void;
  show: (
    message: string,
    options?: {
      title?: string;
      onConfirm?: PopupStoreProps['onConfirm'];
      showCancelButton?: boolean;
      footer?: ReactNode;
    },
  ) => void;
  hide: () => void;
  onClose: (result: boolean) => void;
}

// Store 생성
const PopupStore = observable<PopupStoreProps>({
  isOpen: false,
  isCancelShown: false,
  message: ``,
  title: ``,
  footer: null,
  show(message, options) {
    this.isOpen = true;
    this.message = message;
    this.title = options?.title || ``;
    this.onConfirm = options?.onConfirm;
    this.isCancelShown = !!options?.showCancelButton;
    this.footer = options?.footer;
  },
  hide() {
    this.isOpen = false;
    this.title = ``;
    this.message = ``;
    this.onConfirm = undefined;
    this.isCancelShown = false;
    this.footer = null;
  },
  onClose(result) {
    this.onConfirm?.(result);
    this.hide();
  },
});

// 커스텀 훅 정의
export const usePopupStore: () => typeof PopupStore = () => PopupStore;

export default PopupStore;
