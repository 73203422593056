import { breakpoint } from '@/helpers/BreakpointHelper';
import styled, { CSSProperties } from 'styled-components';

const Row = styled.div<{
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  boardlist?: boolean;
  searchBar?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || `space-between`};
  align-items: ${(props) => props.alignItems || `center`};
  flex-direction: row;
  flex: 1;
  ${({ boardlist }) => {
    if (boardlist) {
      return `
        display:block;
        border-top: 1px solid #2d2926;
      `;
    }
    return ``;
  }}
  ${({ searchBar }) => {
    if (searchBar) {
      return `
      margin-bottom: 32px;
      text-align: right;
      ${breakpoint(`mobile`)} {
        padding-top: 48px;
      }
      `;
    }
    return ``;
  }}

  &.certify {
    margin-left: -16px;
    margin-right: -16px;

    .col-left,
    .col-right {
      padding: 0 16px;
    }
    .col-left {
      width: 192px;

      button {
        width: 100%;
        max-width: 100%;
      }
    }
    .col-right {
      width: calc(100% - 192px);
    }

    ${breakpoint(`mobile`)} {
      margin-left: -8px;
      margin-right: -8px;

      .col-left,
      .col-right {
        padding: 0 8px;
      }
      .col-left {
        width: 96px;
      }
      .col-right {
        width: calc(100% - 96px);
      }
    }
  }
`;

export default Row;
